<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="(row, i) in items"
        :key="i"
        cols="4"
        md="4"
        sm="6"
        class="mx-0 px-0"
      >
        <div
          class="box"
          :class="i !== 0 ? `ml-3` : ''"
        >
          <div class="header">
            <span
              class="head-icon"
              :class="`icon-${color[i]}`"
            >
              <v-icon>{{ row.icon }}</v-icon>
            </span>
            <span class="head-title">{{ row.title }}</span>
          </div>
          <div class="value">
            {{ row.value }}
          </div>
          <div class="sub-value">
            {{ row.desc }}
          </div>
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="6"
        md="6"
        sm="12"
        class="mx-0 px-0"
      >
        <div class="box box-height">
          <div class="header">
            <span class="head-title">Session Information</span>
          </div>
          <div class="content-box">
            <div>
              <span>
                <v-icon>mdi-calendar</v-icon>
                <span class="ml-2">Date</span>
              </span>
              <span class="content-title">
                {{ item.started_at | formatTime("DD-MM-yyyy") }}
              </span>
            </div>
            <div>
              <span>
                <v-icon>mdi-map-marker</v-icon>
                <span class="ml-2">Location</span>
              </span>
              <p class="content-title">
                {{ item.location_name }}
              </p>
            </div>
            <div>
              <span>
                <v-icon>mdi-account</v-icon>
                <span class="ml-2">Customer</span>
              </span>
              <span class="content-title"> {{ item.customer_name }} </span>
            </div>
            <div>
              <span>
                <v-icon>mdi-car-estate</v-icon>
                <span class="ml-2">Vehicle</span>
              </span>
              <span class="content-title"> {{ item.make_and_model }} </span>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="6"
        md="6"
        sm="12"
      >
        <div class="box box-height">
          <div class="header">
            <span class="head-title">Charging Efficiency</span>
          </div>

          <div>
            <div class="d-flex justify-space-between">
              <label for=""> Overall Efficiency </label>
              <span>{{ Math.ceil(progress) }}%</span>
            </div>
            <v-progress-linear v-model="progress">
              <!-- height="25" -->
              <!-- <strong>{{ Math.ceil(progress) }}%</strong> -->
            </v-progress-linear>
          </div>

          <!--  -->
          <div class="mt-4">
            <label for="">Session Status</label>
            <div class="d-flex justify-space-between">
              <status-badge :status="item.status" />
              <label for=""> {{ item.status }}</label>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      color: ["blue", "yellow", "green"],

      progress: 44,
    };
  },
  computed: {
    items() {
      const v = this.item;
      return [
        {
          icon: "mdi-clock",
          title: "Duration",
          value: v.duration,
          desc: `${this.$options.filters.formatTime(v.started_at)} 
          - ${this.$options.filters.formatTime(v.completed_at)}`,
        },
        {
          icon: "mdi-lightning-bolt",
          title: "Energy",
          value: `${
            v.energy_consumed !== "N/A"
              ? v.energy_consumed + " kWh"
              : v.energy_consumed
          }`,
          desc: `"Avg. 37.57 kW"`,
        },
        {
          icon: "mdi-cash",
          title: "Cost",
          value: `PKR ${v.energy_cost}`,
          desc: `${v.rate} PKR/kWh`,
        },
      ];
    },
  },
};
</script>
<style lang="sass" scoped>
.box
    border: 1px solid lightgray
    border-radius: 5px
    padding: 1rem
    .header
        .icon-blue
            i
                color: #3b82f6 !important
        .icon-yellow
            i
                color: #f59e0b !important
        .icon-green
            i
                color: #22c55e !important
        .head-title
            color: #000
            padding-left: 5px
            font-weight: 600
            font-size: 14px
    .value
        padding-top: 5px
        color: #000
        font-size: 24px
        font-weight: 600
    .sub-value
        padding-top: 5px

    .content-box
        div
            display: flex
            justify-content: space-between
        .content-title
            color: #000
            font-weight: 600
            font-size: 14px

        p
            width: 50%
            text-align: end
            margin:0

.box-height
    height: 220px
</style>
